div.modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(114, 0, 180, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  overflow: none;
}

div.modal_container p {
  text-align: center;
}

div.modal {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  background-color: white;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 25vh;
  padding: 30px;
  max-width: 90%;
  transition: var(--default-transition);
}

div.modal > p {
  z-index: 5;
  line-height: 1.7rem;
}

div.modal-product {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white;
  border: 1px solid var(--color3);
  border-radius: 6px;
  width: 1000px;
  max-width: 97vw;
  height: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

div.modal-product div.product-image {
  max-width: 700px;
  width: 100%;
  max-height: 99%;
  background-color: var(--color4);
}
