button.back-button {
  padding: 8px;
  background-color: white;
  box-shadow: var(--light-shadow);
  border-radius: 5px;
  border: 1px solid var(--color3);
  color: var(--color5);
}

button.back-button:hover {
  background-color: var(--main-color);
  color: white;
  transform: scale(1.05);
}

button.back-button svg {
  font-size: 18px;
}
