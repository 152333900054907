footer {
  display: flex;
  background-color: var(--color7);
  justify-content: center;
  width: 100%;
  position: absolute;
  top: calc(100% + 60px);
}

div.footer {
  display: flex;
  justify-content: left;
  flex-direction: row;
  padding: 60px 20px;
  gap: 50px;
  flex-wrap: wrap;
  width: fit-content;
}

div.footer-group {
  display: flex;
  justify-content: left;
  flex-direction: column;
  gap: 8px;
}

p.footer-group-title {
  color: var(--color9);
  margin-bottom: 8px;
  user-select: none;
  font-weight: 900;
}

p.footer-link {
  color: white;
  font-weight: 900;
  transition: var(--default-transition);
}

p.footer-link:hover {
  color: var(--color1);
  cursor: pointer;
}
