div.file_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 22px;
  background-color: white;
}

div.file_input p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

div.file-input-controls {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

svg.file_icon {
  margin-right: 10px;
}

svg.file_icon.img {
  color: var(--color8);
}
