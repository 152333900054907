div.gradient-banner {
  height: 200px;
  width: 1000px;
  max-width: 100%;
  border-radius: 12px;
  position: relative;
  top: -50px;
  animation: slide_to_bottom 300ms;
  transition: var(--default-transition);
}

@keyframes slide_to_bottom {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}
