div.input_tag {
  display: flex;
  align-items: center;
  background-color: var(--color3);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  user-select: none;
}
