div.panel_sidebar {
  width: fit-content;
  max-width: 300px;
}

div.panel_sidebar_option {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid var(--color3);
  transition: var(--default-transition);
}

div.panel_sidebar_option:hover {
  background-color: var(--color4);
  cursor: pointer;
}

div.panel_sidebar_option > * {
  align-self: center;
}

div.panel_sidebar_option p {
  text-align: left;
}

div.panel_sidebar_option:hover p,
div.panel_sidebar_option:hover svg {
  color: var(--main-color);
}

div.panel_sidebar_option.disabled {
  background-color: var(--color3);
  color: var(--color5);
}

div.panel_sidebar_option.disabled svg.panel_sidebar_option-arrow {
  animation: none;
}

div.panel_sidebar_option.disabled:hover svg.panel_sidebar_option-arrow {
  animation: none;
}

div.panel_sidebar_option.disabled * {
  color: var(--color5);
}

div.panel_sidebar_option.disabled:hover {
  cursor: default;
  color: inherit;
}

@keyframes slidetoright {
  from {
    left: 0;
  }
  to {
    left: 6px;
  }
}

@keyframes slidetoright-out {
  from {
    left: 6px;
  }
  to {
    left: 0;
  }
}

svg.panel_sidebar_option-arrow {
  position: relative;
  animation: slidetoright-out 500ms;
}

div.panel_sidebar_option:hover svg.panel_sidebar_option-arrow {
  animation: slidetoright 500ms forwards;
}

div.panel_sidebar_option.selected {
  background-color: var(--main-color);
}

div.panel_sidebar_option.selected p,
div.panel_sidebar_option.selected svg {
  color: white;
}
