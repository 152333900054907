div.session_card {
  position: sticky;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: white;
  width: 240px;
  gap: 20px;
}

div.session_card > button {
  position: absolute;
  top: 12px;
  right: 12px;
}

div.session_card > svg {
  font-size: 28px;
}

div.session_card > div {
  display: flex;
  flex-flow: column;
  gap: 6px;
}

div.session_card > div p {
  text-align: left;
}
