div.collection_card {
  display: flex;
  position: relative;
  width: 256px;
  height: auto;
  max-height: 400px;
  max-width: 400px;
  background-color: white;
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow: hidden;
  aspect-ratio: 1/1;
}

div.img_container > img {
  transition: var(--default-transition);
}

img.col_img_loading {
  opacity: 0;
}

div.collection_card div.img_container {
  padding: 0px;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
}

div.collection_card:hover div.img_container > img {
  opacity: 0.95;
  transform: scale(1.05);
}

div.collection_card img {
  object-fit: cover;
  vertical-align: top;
  width: 100%;
  height: 100%;
  max-width: 100%;
  transition: var(--default-transition);
}

div.collection_info {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  bottom: 0px;
  transition: var(--default-transition);
  color: white;
}

div.collection_info > * {
  align-self: center;
}

div.collection_info > p {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;
  color: white;
}

div.collection_info > div {
  display: flex;
  gap: 4px;
}

.collection_pinned {
  transform: rotate(-45deg);
  position: absolute;
  top: 18px;
  left: 18px;
  color: white;
  z-index: 10;
}

.collection_pinned path {
  stroke: var(--main-color);
  stroke-width: 5px;
}
