div.panel_option {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 120px;
  border: 1px solid var(--color3);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 1px 1px 10px 0px var(--color5);
  background-color: var(--color3);
  transition: var(--default-transition);
}

div.panel_option:hover {
  background-color: white;
  cursor: pointer;
}

div.panel_option > svg,
div.panel_option > p {
  font-size: 28px;
}

div.panel {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 900px;
}
