div.changeable-field {
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 10px;
  width: fit-content;
  background-color: white;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

div.changeable-field:hover {
  cursor: pointer;
  box-shadow: var(--light-shadow);
}

div.changeable-field svg {
  color: var(--text-color);
}
