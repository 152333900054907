div.subscription-status-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  gap: 12px;
}

div.subscription-info-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
}

div.subscription-status {
  display: flex;
  gap: 10px;
  align-items: center;
}
