div.business_page_header {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  position: relative;
  margin-left: 4%;
  margin-top: -80px;
  max-width: 92vw;
}

div.business_page_logo {
  width: fit-content;
  border-radius: 50%;
  border: 4px solid var(--color4);
  width: 160px;
  height: 160px;
}

div.business_page_logo > img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 50%;
}

p.verified_badge {
  background-color: var(--main-color);
  color: white;
  padding: 8px 12px;
  border-radius: 100px;
}

p.verified_badge > svg {
  margin-right: 8px;
}
