button.checkbox {
  width: 25px;
  height: 25px;
  background-color: white;
  color: white;
  border: 2px solid var(--main-color);
  border-radius: 6px;
  padding: 0px;
}

button.checkbox.selected {
  background-color: var(--main-color);
}
