div.sidebar_option {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 32px;
  width: 100%;
  transition: var(--default-transition);
}

div.sidebar_option:hover {
  background-color: #7200b4;
  cursor: pointer;
}

div.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 20px;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  min-width: 60vw;
  z-index: 3;
  background-color: rgba(var(--sidebar-color), 0.7);
  backdrop-filter: blur(5px);
  padding-top: 30px;
}
