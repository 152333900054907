p.status-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  width: 72px;
  min-width: fit-content;
  text-align: center !important;
  font-weight: bold;
}

p.info {
  background-color: #c9e7ff;
  color: rgb(0, 104, 173);
}

p.success {
  background-color: #caffdc;
  color: rgb(0, 173, 66);
}

p.warning {
  background-color: #ffe7b3;
  color: rgb(207, 143, 5);
}

p.error {
  background-color: #ffc2ca;
  color: rgb(201, 2, 45);
}
