div.item_display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
}

div.collection-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

div.collection-header > p {
  letter-spacing: 8px;
}

div.item {
  position: relative;
  overflow: hidden;
  width: 32%;
  min-width: 100px;
}

div.item:hover img {
  filter: blur(3px);
}

div.item_overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  animation: item_overlay 400ms;
  cursor: pointer;
}

@keyframes item_overlay {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Taken from: https://css-tricks.com/aspect-ratio-boxes/ */
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
