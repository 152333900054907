table.plan-compare {
  position: static;
  width: 800px;
  max-width: 95%;
  margin: auto;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
}

table.plan-compare thead {
  height: 80px;
}

table.plan-compare th {
  font-size: 18px;
  text-align: center;
  font-weight: 900;
}

table.plan-compare tr {
  height: 60px;
}

table.plan-compare tr:hover {
  background-color: inherit;
}

.highlighted-cell {
  background-color: #f9f0ff;
  color: var(--main-color);
  font-weight: 900;
}
