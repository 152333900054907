div.payment-method-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  gap: 12px;
}

div.payment-method {
  display: flex;
  gap: 10px;
  align-items: center;
}
