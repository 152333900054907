@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&family=Space+Grotesk:wght@400;700&display=swap");

#root {
  position: relative;
}

:root {
  --main-color: #a200ff;
  --sidebar-color: 66, 0, 104;
  --text-color: #47525e;
  --color1: #edcdff;
  --color2: #7200b4;
  --color3: #e5e9f2;
  --color4: #f8f8f8;
  --color5: #8190a5;
  --color6: #df155c;
  --color7: #3d0061;
  --color8: #30a47d;
  --color9: #cc00ff;
  --warning-color: rgb(255, 183, 106);
  --modal-display: flex;
  --default-transition: all 400ms ease 0s;
  --light-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.hidden {
  display: none;
}

hr.vertical {
  width: 1px;
  height: 100%;
}

li {
  color: var(--text-color);
}

textarea {
  outline: none;
  border-radius: 10px;
  padding: 10px;
  resize: none;
  border: 2px solid white;
  box-sizing: border-box;
  transition: var(--default-transition);
}

textarea:focus {
  border-color: var(--main-color);
}

hr {
  box-sizing: border-box;
  border: 1px solid var(--color3);
  border-radius: 2px;
  width: 100%;
}

select {
  outline: none;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  transition: var(--default-transition);
  background-color: white;
  border: 1px solid var(--color3);
}
option {
  padding: 10px;
}

div.table_container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 400px;
  max-width: 100%;
  overflow-x: auto;
}

table,
th,
td {
  border-collapse: collapse;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-color: var(--color3);
}

table {
  position: sticky;
  top: 0;
  border-spacing: 16px;
  width: 100%;
}

tbody > tr:hover {
  background-color: var(--color3);
}

th,
td {
  text-align: center;
  padding: 8px;
}

td > div,
td > p {
  margin: auto;
}

td.button {
  text-align: center;
  color: var(--color2);
  font-size: 13px;
}

div.button {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: var(--color2);
  font-size: 13px;
  padding: 10px;
  transition: var(--default-transition);
}

div.button:hover,
td.button:hover {
  background-color: var(--color3);
  cursor: pointer;
  color: var(--main-color);
}

img.modal {
  max-width: 90vw;
  max-height: 90vh;
}

header a:hover {
  color: var(--color1);
}

section {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

div.inline {
  display: flex;
  align-items: center;
  gap: 10px;
}

button.inline {
  display: inline-flex;
  align-items: center;
}

img.transparent {
  opacity: 0;
}

button {
  background-color: var(--main-color);
  color: white;
  border-color: white;
  border-style: none;
  padding: 0.6rem 1rem 0.6rem 1rem;
  font-weight: 700;
  border-radius: 10rem;
  transition: var(--default-transition);
}

button > * {
  vertical-align: middle;
}

button:hover {
  background-color: var(--color2);
  cursor: pointer;
}

button:disabled {
  background-color: var(--color7);
  color: var(--main-color);
}

button:disabled:hover {
  cursor: not-allowed;
}

button.red {
  padding: 8px;
  background-color: transparent;
  color: var(--color6);
  border: 2px solid var(--color6);
}

button.red:hover {
  background-color: var(--color6);
  color: white;
}

button.red:disabled {
  background-color: #690c2d;
  color: var(--color6);
}

button.transparent {
  background-color: transparent;
  border: 2px solid var(--main-color);
  color: var(--main-color);
}

button.transparent:hover {
  background-color: var(--main-color);
  color: white;
}

button.transparent.white {
  border: 2px solid white;
  color: white;
}

button.transparent.white:hover {
  background-color: white;
  color: var(--main-color);
}

div.icon_circle {
  background-color: var(--color3);
  padding: 8px;
  border-radius: 50px;
}

svg {
  flex-shrink: 0;
}

svg.button {
  color: var(--color7);
}

svg.button:hover {
  cursor: pointer;
  color: var(--main-color);
}

.text-center {
  text-align: center;
}

.text-white {
  color: white;
}

.white {
  background-color: white;
}

.text-purple {
  color: var(--main-color);
}

.purple {
  background-color: var(--main-color);
}

.light {
  background-color: var(--color5);
}

.text-light {
  color: var(--color5);
}

button.white {
  color: var(--main-color);
  border-color: var(--main-color);
}

button.white:hover {
  background-color: var(--color1);
}

button.white:disabled:hover {
  background-color: var(--color7);
}

button.wide {
  padding-left: 40px;
  padding-right: 40px;
}

a {
  text-decoration: none;
  transition: var(--default-transition);
}

.link {
  color: var(--main-color);
}

.link:hover {
  color: var(--color2);
}

.href:hover {
  cursor: pointer;
}

b {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

.highlight {
  color: var(--main-color);
}

p {
  width: fit-content;
  margin: 0;
  color: var(--text-color);
}

.codetext {
  padding: 4px 12px;
  background-color: var(--color3);
  border-radius: 8px;
}

.bold {
  font-weight: 900;
}

.linespace-s {
  margin-bottom: 8px;
}

.linespace-sm {
  margin-bottom: 12px;
}

.linespace-m {
  margin-bottom: 16px;
}

.linespace-l {
  margin-bottom: 20px;
}

.linespace-xl {
  margin-bottom: 28px;
}

.linespace-xxl {
  margin-bottom: 36px;
}

input {
  border-radius: 6px;
  border: 2px solid white;
  padding: 8px;
  outline: none;
  transition: var(--default-transition);
}

input.form {
  box-sizing: border-box;
  width: 100%;
}

input:focus {
  border-color: var(--main-color);
}

input.underlined,
textarea.underlined {
  background-color: var(--color4);
}

input.underlined:focus,
textarea.underlined:focus {
  border: 2px solid white;
  border-bottom-color: var(--main-color);
}

.fit_screen {
  max-width: 100vw;
}

div.form {
  max-width: 100vw;
  display: flex;
  flex-flow: column;
  gap: 15px;
  height: fit-content;
}

div.form p {
  text-align: left;
}

.t {
  font-size: 10px;
}

.st {
  font-size: 12px;
}

.s {
  font-size: 14px;
}

.sm {
  font-size: 18px;
}

.m {
  font-size: 22px;
}

.l {
  font-size: 26px;
}

.xl {
  font-size: 34px;
}

.xxl {
  font-size: 42px;
}

div.content {
  margin-top: 67px;
  animation: content_intro 700ms;
  min-height: calc(100vh - 67px - 80px);
}

@keyframes content_intro {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  90% {
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
  }
}

.left {
  margin-right: auto;
}

p.left {
  text-align: left;
}

.right {
  margin-left: auto;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

button.centered,
img.centered {
  display: block;
}

div.centered_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 40px;
}

div.container {
  width: fit-content;
  max-width: 100%;
  background-color: var(--color4);
  padding: 20px;
  box-sizing: border-box;
  transition: var(--default-transition);
}

.shadowed {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

svg.shadowed {
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.4));
  box-shadow: none;
}

.rounded {
  border-radius: 20px;
}

.semi_rounded {
  border-radius: 8px;
}

div.card_display {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.mobile {
  display: none;
}

.desktop {
  display: initial;
}

@media (max-width: 800px) {
  .mobile {
    display: initial;
  }
  .desktop {
    display: none;
  }
}

/* from: https://www.w3schools.com/1/css_tooltip.asp */
.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
}

.tooltip .tooltiptext {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.5);
  transition: all 200ms ease 0s;
  opacity: 0;
  min-width: 100px;
  width: 150%;
  max-width: 300px;
  background-color: white;
  color: var(--color7);
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  font-size: 14px;
  font-weight: 700;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

.loading {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #e2e2e2 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.font-secondary {
  font-family: "Space Grotesk", sans-serif;
}
