div.landing_banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw;
  height: calc(100vh - 67px);
  background: linear-gradient(
    -45deg,
    rgba(80, 0, 96, 1),
    rgba(248, 0, 255, 1),
    var(--main-color)
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

div.landing_banner p {
  user-select: none;
  color: white;
}
