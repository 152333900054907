div.auth_method {
  position: relative;
  display: flex;
  border: 2px solid var(--main-color);
  align-items: center;
  gap: 12px;
  min-height: fit-content;
  height: 100px;
  min-width: 100px;
  max-height: 500px;
  overflow: hidden;
  flex-direction: row;
  flex-shrink: 0;
}

div.auth_method > svg {
  font-size: 32px;
}

div.auth_method p {
  text-align: left;
}

div.auth_method-selected {
  position: relative;
  box-sizing: border-box;
  right: -20px;
  display: flex;
  background-color: var(--main-color);
  min-height: calc(100% + 40px);
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
}

div.auth_method-selected > p {
  color: white;
}
