div.card-number-input-container {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  max-width: 100%;
}

input.card-number-input {
  background-color: white;
  padding-right: 50px;
  letter-spacing: 0.16rem;
  width: 250px;
  max-width: 100%;
}

svg.card-brand-icon {
  position: absolute;
  right: 10px;
  color: var(--color5);
}
