div.login-signup {
  background: rgb(162, 0, 255);
  background: linear-gradient(
    32deg,
    rgba(162, 0, 255, 1) 59%,
    rgba(189, 74, 255, 1) 100%
  );
  border: none;
  font-size: 32px;
  padding: 24px;
  width: fit-content;
  max-width: 600px;
  transition: all 0.5s ease 0ss;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 1px 1px 10px 0px rgb(188, 188, 188);
  position: relative;
  transition: var(--default-transition);
}

div.login-signup:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.5s;
}

div.login-signup:hover {
  padding-right: 32px;
  padding-left: 16px;
}

div.login-signup:hover:after {
  opacity: 1;
  right: 10px;
  color: white;
}

div.login-signup p {
  color: white;
}
