.text-divider {
  --text-divider-gap: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: 7px;
  width: 100%;
  margin: 36px 0px;
  position: relative;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: var(--color3);
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}
