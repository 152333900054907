span.tooltip-container {
  position: relative;
}

span.tooltip-container > svg {
  color: var(--main-color);
}

span.tooltip-component {
  box-sizing: border-box;
  position: absolute;
  bottom: calc(100% + 16px);
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 20px;
  background-color: var(--color4);
  border: 1px solid var(--color3);
  border-radius: 8px;
  box-shadow: var(--light-shadow);
  animation: fade_slideup 0.4s ease;
  width: 300px;
  font-size: 12px;
}

@keyframes fade_slideup {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}
