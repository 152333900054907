div.mercado-pago-secure-card {
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: #e0f7ff;
  border-left: 4px solid #00bcff;
  width: 100%;
}

div.mercado-pago-secure-card p {
  color: #00698f;
  line-height: 1.5rem;
}

div.mercado-pago-secure-card button {
  border-radius: 4px;
  background-color: #00bcff;
}

div.mercado-pago-secure-card button:hover {
  background-color: #00a0d8;
}
