button.notification {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  width: 50px;
  height: 50px;
  animation: 600ms slide_to_left;
  transition: var(--default-transition);
}

@keyframes slide_to_left {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes notifications_intro {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

div.notification_container {
  position: fixed;
  box-sizing: border-box;
  width: 280px;
  max-width: calc(100vw - 20px);
  height: 400px;
  max-height: calc(100vh - 82px);
  bottom: 82px;
  right: 20px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: white;
  z-index: 2;
  border-radius: 15px;
  /* animation: 350ms notifications_intro; */
}

div.notification {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 16px;
  transition: var(--default-transition);
}

div.notification:hover {
  background-color: var(--color3);
  cursor: pointer;
}

div.notification svg {
  color: var(--color7);
}

div.notification-discard {
  position: absolute;
  right: 20px;
}
