div.progress-bar-placeholder {
  display: flex;
  justify-content: left;
  background-color: var(--color3);
  border-radius: 6px;
  height: 30px;
  background-color: rgba(80, 0, 96, 1);
}

div.progress-bar {
  height: 30px;
  background: linear-gradient(
    90deg,
    rgba(80, 0, 96, 1) 10%,
    rgba(248, 0, 255, 1) 90%
  );
  border-radius: 6px;
  transition: var(--default-transition);
}
