div.payment-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

div.payment-form > input {
  background-color: white;
  letter-spacing: 0.16rem;
}

input.card-holder-name {
  width: 292px;
  max-width: 100%;
}

div.card-exp-container {
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 100%;
}

div.cardholder-id-container {
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 100%;
}

div.cardholder-id-container > input {
  background-color: white;
}

div.cardholder-id-container > select {
  width: 80px;
}

/* #cardNumberSecureField_container,
#securityCodeSecureField_container,
#expirationDateSecureField_container {
  display: none;
} */
