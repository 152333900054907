:root {
  --info-color: rgb(0, 66, 141);
  --warning-color: rgb(248, 141, 0);
  --success-bg-color: rgb(180, 255, 220);
  --success-color: rgb(18, 145, 86);
}

div.form_message {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  border-left-width: 3px;
  border-left-style: solid;
}

div.form_message > p {
  word-wrap: break-word;
  text-align: left;
  line-height: 1.5em;
  font-size: 13px;
}

div.form_message > p > svg {
  margin-right: 10px;
  vertical-align: middle;
}

div.form_message.error {
  background: #ff9cba;
  color: var(--color6);
  border-color: var(--color6);
}

div.form_message.error > p {
  color: var(--color6);
}

div.form_message.info {
  background-color: rgb(223, 239, 255);
  color: var(--info-color);
  border-color: var(--info-color);
}

div.form_message.info > p {
  color: var(--info-color);
  vertical-align: middle;
}

div.form_message.warning {
  background-color: rgb(255, 246, 204);
  color: var(--warning-color);
  border-left-color: var(--warning-color);
}

div.form_message.warning > p {
  color: var(--warning-color);
}

div.form_message.success {
  background-color: var(--success-bg-color);
  color: var(--success-color);
  border-color: var(--success-color);
}

div.form_message.success > p {
  color: var(--success-color);
}
