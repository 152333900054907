div.email-verified-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
  width: 600px;
  max-width: 90%;
  position: relative;
  top: 50px;
  border-radius: 16px;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

div.email-verified-box > svg {
  height: auto;
}

div.email-verified-box p {
  text-align: center;
}
