div.select-options-container {
  position: absolute;
  top: 5vh;
  width: 600px;
  max-width: 95vw;
  max-height: 90vh;
  overflow-y: auto;
}

div.select-options-container input {
  box-sizing: border-box;
  width: 100%;
  background-color: white;
}

div.select-option {
  background-color: white;
  padding: 12px 24px;
  margin-bottom: 3px;
}

div.select-option:hover {
  background-color: #f9f0ff;
  cursor: pointer;
}
