div.privacy-policy-page {
  padding-top: 54px;
  width: 700px;
  max-width: calc(90% - 20px);
}

div.privacy-policy-page * {
  line-height: 2rem;
}

div.privacy-policy-page p {
  margin-bottom: 18px;
}

div.privacy-policy-page h1 {
  margin: 0;
}

.indented {
  margin-left: 36px;
}
