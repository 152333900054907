div.password-input {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}

div.password-input input {
  padding-right: 34px;
}

div.password-input svg {
  position: absolute;
  right: 12px;
  color: var(--color5);
  transition: var(--default-transition);
}

div.password-input svg:hover {
  color: var(--color7);
  cursor: pointer;
}
