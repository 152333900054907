div.product-content-container {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  flex: 1 0 auto;
  flex-direction: column;
}

div.product-content {
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 100%;
}

div.product-contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

div.product-contact button {
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.product-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--main-color);
  padding: 2px;
}

.product-logo img.placeholder {
  object-fit: cover;
  border-radius: 50%;
}

div.product-header {
  display: flex;
  align-items: center;
  gap: 10px;
  height: fit-content;
}

button.delete-product-button {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  font-size: 20px;
  width: 50px;
  height: 50px;
  background: var(--color6);
  color: white;
}

button.delete-product-button:hover {
  background-color: #af0c45;
}
