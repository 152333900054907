header {
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  align-items: center;
  background-color: var(--main-color);
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  box-sizing: border-box;
  z-index: 2;
  min-height: 67px;
}

header > * {
  color: white;
}

header h1 {
  margin: 0;
}

header > ul {
  list-style: none;
}

header li {
  display: inline-block;
  text-decoration: none;
  margin-right: 30px;
  font-weight: bold;
  transition: var(--default-transition);
  color: white;
}

header li:hover {
  cursor: pointer;
  color: var(--color1);
}

div.user-card {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--color2);
  border-radius: 10px;
  transition: var(--default-transition);
  overflow: hidden;
  margin: 0 20px;
}

div.user-card-name {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  padding-left: 20px;
  height: 40px;
  box-sizing: border-box;
}

div.user-card-name * {
  color: white;
}

div.user-card-button {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--main-color);
  transition: var(--default-transition);
}

div.user-card-button:hover {
  cursor: pointer;
  background-color: var(--color1);
}

div.user-card-button svg {
  color: var(--main-color);
}

@media (max-width: 800px) {
  header {
    padding: 0;
  }
}
