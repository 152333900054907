div.link-card-container {
  padding: 20px 20px;
  background-color: #f6e6ff;
  border-radius: 12px;
  border-left: 4px solid var(--main-color);
  display: flex;
  gap: 16px;
  flex-direction: column;
}

p.link-card-title {
  color: var(--main-color);
}

p.link-card-title svg {
  margin-right: 10px;
}

div.link-card-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

div.link-card-link {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: white;
  padding: 8px 16px;
  border-radius: 12px;
  overflow: hidden;
  font-size: 14px;
  height: 34px;
  font-weight: 600;
  color: var(--color2);
}

button.link-card-copy {
  box-sizing: border-box;
  color: white;
  background-color: var(--main-color);
  margin: -8px -16px;
  margin-right: 0;
  border-radius: 0;
  width: 104px;
  min-width: 104px;
}

button.link-card-copy > svg {
  margin-right: 9px;
}
