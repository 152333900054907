div.horizontal_menu {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  justify-content: center;
  gap: 8px;
}

div.horizontal_menu_option {
  display: flex;
  align-items: center;
  height: 37px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 20px;
  text-align: center;
  vertical-align: middle;
  color: var(--color5);
  border-bottom: 0px solid var(--main-color);
  min-width: fit-content;
  font-weight: 700;
  border-radius: 4px;
}

div.horizontal_menu_option:hover {
  cursor: pointer;
  background-color: var(--color3);
}

div.horizontal_menu_option.selected {
  border-bottom-width: 2px;
  color: var(--main-color);
}
