/* div.code_input_container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

input.code {
  width: 20px;
  text-align: center;
} */

input.code-input {
  text-align: center;
  letter-spacing: 24px;
}
